import { useRef, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

// 6LdjvmwoAAAAAKNwG9JVOufJe_L7xCrVU1d7rujx
export const Contact = () => {
    const form = useRef();
    const [isSending, setIsSending] = useState(false);
    const [capVal, setCapVal] = useState(null);

    const sendEmail = (e) => {
        e.preventDefault();

        // Prevent multiple clicks while email is being sent
        if (isSending) {
            return;
        }

        setIsSending(true);

        emailjs.sendForm('service_6tgck9z', 'template_uv8kupi', form.current, '1BMfgEz105M__W7Wi')
            .then((result) => {
                alert("Deine Nachricht wurde verschickt!");
            }, (error) => {
                console.log("Ups! Da ist etwas schiefgelaufen.");
            })
            .finally(() => {
                setIsSending(false);
            });
    };

    return (
        <section className="contact" id="connect">
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                        <h2>Schreib uns eine Nachricht</h2>
                        <form ref={form} onSubmit={sendEmail}>
                            <Row>
                                <Col sm={6} className='px-1'>
                                    <label>Name*</label>
                                    <input type="text" name="user_name" required />
                                </Col>
                                <Col sm={6} className='px-1'>
                                    <label>Email*</label>
                                    <input type="email" name="user_email" required />
                                </Col>
                                <Col sm={6} className='px-1'>
                                    <label>Nachricht*</label>
                                    <textarea name="message" />
                                    <ReCAPTCHA
                                        sitekey="6LdjvmwoAAAAAKNwG9JVOufJe_L7xCrVU1d7rujx"
                                        onChange={(val) => setCapVal(val)} />
                                    <input disabled={!capVal || isSending} type="submit" value="Send" required />
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

import { Col, Container, Row } from "react-bootstrap"
import projImg1 from "../assets/img/fox-free-icon-font.png"
import projImg2 from "../assets/img/beer-free-icon-font.png"
import projImg3 from "../assets/img/balloons-free-icon-font.png"
import projImg4 from "../assets/img/restaurant-free-icon-font.png"
import projImg5 from "../assets/img/video-camera-free-icon-font.png"
import projImg6 from "../assets/img/sword-free-icon-font.png"
import projImg7 from "../assets/img/christmas-bell.png"
import projImg8 from "../assets/img/santa.png"
import projImg9 from "../assets/img/musik-note.png"
import projImg10 from "../assets/img/handschlag.png"
import projImg11 from "../assets/img/ski.png"


export const Veranstaltungen = () => {

    return(

        <section className="veranstaltungen" id="veranstaltungen">
            <Container>
                <Row>
                    <Col>
                        <h2>Veranstaltungen</h2>
                        <br />
                        <h4>im Sommersemester 2024</h4>
                        <br />
                        <br />
                        <div className="eventBox">
                        <div className="eventBox1">
                            <img src={projImg2} alt="" />
                            <h4>Ankneipe</h4>
                            <h5>12.04.2024</h5>
                            <p>20:00 c.t.</p>
                        </div>
                        <div className="eventBox2">
                            <img src={projImg1} alt="" />
                            <h4>SV-Fuxenwochenende in Braunschweig</h4>
                            <h5>03.05.2024 bis 05.05.2024</h5>
                        </div>
                        <div className="eventBox3">
                            <img src={projImg4} alt="" />
                            <h4>Herrentag mit Boßeln & Essen</h4>
                            <h5>20.10.2023 bis 22.10.2023</h5>
                        </div>
                        <div className="eventBox4">
                            <img src={projImg10} alt="" />
                            <h4>SV-Segeln</h4>
                            <h5>17.05.2024 bis 21.05.2024</h5>
                        </div>
                        </div>
                            <br />
                            <br />
                            <br />
                        <div className="eventBoxRow2">
                            <div className="eventBoxRow21">
                                <img src={projImg9} alt="" />
                                <h4>105. Stiftungsfest - Begrüßungsabend</h4>
                                <h5>24.05.2024</h5>
                                <p>18:00 s.t.</p>
                            </div>
                            <div className="eventBoxRow22">
                                <img src={projImg10} alt="" />
                                <h4>105. Stiftungsfest - SV-Meisterschaft</h4>
                                <h5>25.05.2024</h5>
                                <p>11:00 s.t.</p>
                            </div>
                            <div className="eventBoxRow23">
                                <img src={projImg2} alt="" />
                                <h4>105. Stiftungsfest - Festkommers</h4>
                                <h5>25.05.2024</h5>
                                <p>20:00 c.t.</p>
                            </div>
                            <div className="eventBoxRow24">
                                <img src={projImg4} alt="" />
                                <h4>Frühschoppen mit Brunch</h4>
                                <h5>26.05.2024</h5>
                                <p>11:00 s.t.</p>
                            </div>
                        </div>

                        <br />
                            <br />
                            <br />
                        <div className="eventBoxRow3">
                            <div className="eventBoxRow31">
                                <img src={projImg9} alt="" />
                                <h4>SV-Musikwochenende in Göttingen</h4>
                                <h5>31.05.2024 bis 02.06.2024</h5>
                            </div>
                            <div className="eventBoxRow32">
                                <img src={projImg3} alt="" />
                                <h4>Mottokneipe - 80er</h4>
                                <h5>08.06.2024</h5>
                                <p>20:00 c.t.</p>
                            </div>
                            <div className="eventBoxRow33">
                                <img src={projImg2} alt="" />
                                <h4>Abkneipe</h4>
                                <h5>13.07.2024</h5>
                                <p>20:00 c.t.</p>
                            </div>
                            <div className="eventBoxRow34">
                                <img src={projImg10} alt="" />
                                <h4>Bierolympiade</h4>
                                <h5>26.07.2024 bis 28.07.2024</h5>
                            </div>
                        </div>
                        <br />
                            <br />
                            <br />
                        <div className="eventBoxRow3">
                            <div className="eventBoxRow31">
                                <img src={projImg2} alt="" />
                                <h4>Stadtparkkneipe mit der AMDV Caecilia</h4>
                                <h5>17.08.2024</h5>
                                <p>15:00 c.t.</p>
                            </div>
                            <div className="eventBoxRow32">
                                <img src={projImg4} alt="" />
                                <h4>Indischer Nachmittag mit der AMDV Caecilia</h4>
                                <h5>07.09.2024</h5>
                                <p>15:00 s.t.</p>
                            </div>
                            <div className="eventBoxRow33">
                                <img src={projImg10} alt="" />
                                <h4>OV-Stammtisch</h4>
                                <h5>3. Dienstag</h5>
                                <p>18:00</p>
                            </div>
                            <div className="eventBoxRow34">
                                <img src={projImg2} alt="" />
                                <h4>Bergfest</h4>
                                <h5>Mittwochs</h5>
                                <p>19:00 s.t.</p>
                            </div>
                        </div>
                        {/*<br />
                            <br />
                            <br />
                        <div className="eventBoxRow3">
                            <div className="eventBoxRow31">
                                <img src={projImg2} alt="" />
                                <h4>Bierbrauen</h4>
                                <h5>27.10.2023 bis 29.10.2023</h5>
                                <h5>&</h5>
                                <h5>17.11.2023 bis 19.11.2023</h5>
                            </div>
                            <div className="eventBoxRow32">
                                <img src={projImg10} alt="" />
                                <h4>OV-Stammtisch</h4>
                                <h5>2. Mittwoch im Monat</h5>
                            </div>
                            <div className="eventBoxRow33">
                                <img src={projImg9} alt="" />
                                <h4>Chorprobe</h4>
                                <h5>Jeden Montag</h5>
                                <p>19:00</p>
                            </div>
                            <div className="eventBoxRow34">
                                <img src={projImg4} alt="" />
                                <h4>Küchenschnack</h4>
                                <h5>TBA</h5>
                            </div>
                        </div>
*/}
{/*
                        <br />
                            <br />
                            <br />
                        <div className="eventBoxRow4">
                            <div className="eventBoxRow41">
                                <img src={projImg11} alt="" />
                                <h4>SV-Skipokal</h4>
                                <h5>10.02. bis 12.02.2023</h5>
                            </div>
                            <div className="eventBoxRow42">
                                <img src={projImg2} alt="" />
                                <h4>Abkneipe</h4>
                                <h5>25.02.2023</h5>
                                <p>20:00 c.t.</p>
                            </div>
                            <div className="eventBoxRow43">
                                <img src={projImg4} alt="" />
                                <h4>Küchenschnack</h4>
                                <h5>TBA</h5>
                            </div>
                            <div className="eventBoxRow44">
                                <img src={projImg1} alt="" />
                                <h4>Fuxenstunden</h4>
                                <h5>TBA</h5>
                            </div>
                        </div>
                        */}
                    </Col>
                </Row>
            </Container>
        </section>

    )
}